import logoCompleta from '../../assets/Services/logoCompleta.svg';
import api from '../../assets/Services/servicos/api.svg';
import data from '../../assets/Services/servicos/data.svg';
import design from '../../assets/Services/servicos/designicone.svg';
import dev from '../../assets/Services/servicos/dev.svg';
import micro from '../../assets/Services/servicos/microservices.svg';
import mobile from '../../assets/Services/servicos/mobile.svg';
import qa from '../../assets/Services/servicos/qa.svg';
import users from '../../assets/Services/servicos/user.svg';
import web from '../../assets/Services/servicos/web.svg';
import '../../styles/index/Services.css';
import { i18n } from '../../translate/i18n';


export function Services() {
  return (
    <>
      <div id='servicos'  className="containerBloco2">
      <div className="superior"></div>

        <div id="blocoDois">
        
          <div className="col-A">
            <img id="logo" src={logoCompleta} alt="" />
          </div>

          <div className="col-B">
            <h2>{i18n.t('titles.produtos')}</h2>
            <p>
            {i18n.t('texts.messages.produtos')}
              <br />
              <br />
              {i18n.t('texts.messages.produtos1')}
            </p>
          </div>
        </div>
      </div>

        

    
      
      <div  className='servicos'>
    
      
        <div className="design ">
        
          <img className="imagemGrid" src={design} alt="Design Sprint" /> 
          <h1 className="tituloGrid">Design Sprint</h1>
           <p className="paragrafoGrid">
           {i18n.t('texts.messages.servicosDesign')}
          </p>
        </div>
        <div className="design">
          <img className="imagemGrid" src={users} alt="User Experience" />
          <h1 className="tituloGrid">User Experience</h1>
          <p  className="paragrafoGrid">
          {i18n.t('texts.messages.servicosUser')}
          </p>
          
        </div>
        <div className="design">
          <img className="imagemGrid" src={data} alt="Data Science" />
          <h1 className="tituloGrid">Data Science</h1>
          <p className="paragrafoGrid">
          {i18n.t('texts.messages.servicosData')}
          </p>
        </div>

        <div className="design ">
          <img className="imagemGrid" src={dev} alt="DevSecOps" />
          <h1 className="tituloGrid">DevSecOps</h1>
          <p className="paragrafoGrid">
          {i18n.t('texts.messages.servicosDev')}
          </p>
        </div>
        <div className="design ">
          <img className="imagemGrid" src={mobile} alt="Mobile Apps" />
          <h1 className="tituloGrid">Mobile Apps</h1>
          <p className="paragrafoGrid">
          {i18n.t('texts.messages.servicosMobile')}
          </p>
        </div>
        <div className="design ">
          <img className="imagemGrid" src={web} alt="Web Applications" />
          <h1 className="tituloGrid">Web Applications</h1>
          <p className="paragrafoGrid">
          {i18n.t('texts.messages.servicosWeb')}
          </p>
        </div>

        <div className="design ">
          <img className="imagemGrid" src={qa} alt="Qa" />
          <h1 className="tituloGrid">QA</h1>
          <p className="paragrafoGrid">
          {i18n.t('texts.messages.servicosQa')}
          </p>
        </div>
        <div className="design ">
          <img className="imagemGrid" src={micro} alt="Microservices" />
          <h1 className="tituloGrid">Microservices</h1>
          <p className="paragrafoGrid">
          {i18n.t('texts.messages.servicosMicro')}
          </p>
        </div>
        <div className="design ">
          <img className="imagemGrid" src={api} alt="Api" />
          <h1 className="tituloGrid">APIs</h1>
          <p className="paragrafoGrid">
          {i18n.t('texts.messages.servicosApi')}
          </p>
        </div>
      </div>
    </>
  )
}
