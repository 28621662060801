const messages = {
  pt: {
    translations: {
      titles: {
        blocoPumpkin:
          "Somos uma empresa de tecnologia focada em conectar pessoas e transformar negócios.",
        todoNegocioDigital: "Todo negócio é ",
        todoNegocio:
          "Criamos tecnologia que impulsionam a mudança e transformam negócios.",
        produtos: "Produtos e Experiências Digitais",
        metodologia: "Como fazemos?",
        carousel1: "Imersão e pesquisa",
        carousel2: "Service Design",
        carousel3: "Evolução das Métricas",
        carousel4: "Design Sprint",
        carousel5: "Usabilidade e Visual Design",
        carousel6: "Teste de Usabilidade",
        tecnologias: "Algumas das tecnologias que dominamos",
        contato: "Fale com a gente!",
        servicos: "Serviços",
        metodologiaMenu: "Metodologia",
        pumpkin: "Pumpkin",
        contacts: "Contato",
      },

      texts: {

        contact:{
          obrigado: "Obrigado!",
          logo: "Logo Entraremos em contato."
        },


        messages: {
          blocoPumpkin1:
            "Nascemos na era da cultura digital e alimentamos o nosso foco em criar soluções únicas, inovadoras e moldadas à necessidade de cada Cliente.",
          blocoPumpkin2:
            "Com um Time altamente capacitado e com dedicação 100% ao negócio, simplificamos a tecnologia para entregar a melhor qualidade ao seu projeto.",
          todoNegocio:
            "Caminhos ao seu lado em todos os desafios, construindo uma jornada de resultados com segurança, transparência, produtividade e credibilidade.",
          produtos:
            "Desenvolvemos produtos que simplificam as interações entre pessoas, produtos e serviços. Criamos tecnologia que impulsionam a mudança e transformam negócios. ",
          produtos1:
            "Acreditamos que o mundo está em constante movimento, por isso não paramos. Aliamos o desenvolvimento ágil com as novas tecnologias para entregar as melhores soluções para nossos clientes.",
          servicosDesign:
            "Estruturamos a sua ideia de maneira ágil, colaborativa e inovadora para tangibilizar seu projeto em um curto espaço de tempo.",
          servicosUser:
            "Construimos projetos que proporcionam melhor interação e visam levar experiências encantadoras para os consumidores.",
          servicosData:
            "Acompanhe os dados e informações inerentes ao seu negócio e tenha uma visão estratégica para identificar problemas, soluções e oportunidades.",
          servicosDev:
            "Pensamos na infraestrutura, desenvolvimento e na segurança das informações, facilitando o processo de conformidade com a LGPD e outras regulamentações.",
          servicosMobile:
            "Entregamos o melhor da tecnologia ao alcance das suas mãos.",
          servicosWeb:
            "Construímos aplicativos web de forma rápida e descomplicada, com um modelo de programação moderno e seguro.",
          servicosQa:
            "A qualidade é nossa prioridade. Realizamos um processo sistemático de testes para garantir um produto final livre de erros ou problemas.",
          servicosMicro:
            "Construímos projetos bem divididos e organizados para facilitar a escalabilidade do seu software.",
          servicosApi:
            "Possibilidade de integrar sistemas com segurança dos dados e facilidade no intercâmbio entre informações com diferentes linguagens de programação.",

          metodologia:
            "Pensamos, construímos e entregamos produtos digitais incríveis. Nossa prioridade é atender as demandas dos nossos clientes com agilidade, transparência e qualidade. ",
          metodologia1:
            "Entendemos a necessidade dos nossos clientes e mapeamos o melhor caminho a ser trilhado até a entrega final.",
          carousel1:
            "Identificamos a persona para mapear todas as jornadas, realizar uma validação de hipóteses e insights para resolver os problemas.",
          carousel2:
            "Mapa de todo o serviço, priorizando a comunicação entre a áreas e oportunidades para novas interações.",
          carousel3:
            "Analisamos a evolução dos dados para entender qual o melhor direcionamento estratégico.",
          carousel4:
            "Desenvolvimento de uma visão clara de soluções e próximos passos.",
          carousel5:
            "Foco na componentização, padrões visuais e de usabilidade dos produtos.",
          carousel6:
            "Levantamos todos os gaps e identificamos as melhorias necessárias para o produto.",
          footer: "Ilustrações de Chattapat (NounProject)",
          footerDireitos: "© 2022 Todos os direitos reservados. ",
        },

        inputs: {
          name: "Nome*",
          email: "E-mail*",
          company: "Empresa",
          phone: "Celular",
          subject: "Assunto*",
          message: "Mensagem*",
        },
        btn: {
          envio: "Enviar",
        },
        error: {
          name: "Mínimo de 2 caracteres.",
          email: "E-mail inválido.",
          phone: "Celular inválido.",

          required: "Campo obrigatório.",
          message: "Mínimo de 2 caracteres",
          max: "Maximo 100 caracteres",
          max500: "Maximo 500 caracteres",
        },
        unsubscribe:{
          title: "Sentiremos sua falta!",
          message:"Você cancelou sua inscrição e não deverá mais receber e-mails da Pumpkin.",
        },
      },
    },
  },
};

export { messages };
