import { useState } from "react";
import email from "../../../assets/Home/Email.svg";
import facebook from "../../../assets/Home/Facebook.svg";
import instagram from "../../../assets/Home/Instagram.svg";
import linkedin from "../../../assets/Home/linkedin.svg";
import whatsapp from "../../../assets/Home/whatsapp.svg";
import fecha from "../../../assets/navbar/fechar.svg";
import logoNavBar from "../../../assets/navbar/logoNavBar.svg";
import menuReduzido from "../../../assets/navbar/menuReduzido.svg";

import "../../../styles/index/NavBar.css";

import { LanguageSwitcher } from "../../../translate";

export function NavBarUnsubscribe(page: any) {
  const [navBar, setNavBar] = useState<string>(page.page);

  window.onscroll = () => {
    if (window.scrollY === 0) {
      setNavBar(page.page);
      return;
    }
    setNavBar("scrolled");
  };

  const nav =
    navBar === "home"
      ? {
          logo: logoNavBar,
          color: { color: "white" },
          background: "rgba(2, 1, 10, 0.7)",
        }
      : {
          style: {
            paddingTop: "0",
            boxShadow: "0px 3px 30px #00000038",
            transition: "background .25s ease-in-out",
          },
          logo: logoNavBar,
          background: "#02010A",
          buttonStyle: {
            backgroundImage: `url(width='25' height='25' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(0, 0, 0)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            width: "45px",
          },
        };


  const [navCollapse, setNavCollapse] = useState<boolean>(false);

  function showNavCollapse(navCollapse: boolean) {
    setNavCollapse(!navCollapse);
  }

  const [navStates, setNavStates] = useState<boolean>(false);

  const navState =
    navCollapse === false
      ? {
          display: "none",
        }
      : {};

  return (
    <div
      className={`container-fluid px-0 ${navStates ? "open" : ""} `}
      id="container-nav"
      style={nav.style}
    >
      <nav
        className="navbar navbar-expand-lg px-5-nav nav-bar1"
        style={{ background: nav.background }}
      >
        <a className={`navbar-brand ${navStates ? "open" : ""}`} href="/">
          <img
            id="logo-menu-nav-top"
            src={nav.logo}
            alt="logo pumpkin"
            style={{ maxWidth: "171px", maxHeight: "40px" }}
          />
        </a>

        <button
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-label="Toggle navigation"
          style={nav.buttonStyle}
          onClick={() => {
            showNavCollapse(navCollapse);
            setNavStates((open) => !open);
          }}
        >
          {!navStates ? (
            <img id="menuReduzido" src={menuReduzido} alt="" />
          ) : (
            <img id="menuReduzido-fecha" src={fecha} alt="" />
          )}
        </button>
        <div className="collapse navbar-collapse justify-content-end">
          <ul>
           

            <li>
              <LanguageSwitcher />
            </li>
          </ul>
        </div>
      </nav>

      <section
        onClick={() => {
          showNavCollapse(navCollapse);
          setNavStates((open) => false);
        }}
        id="nav-ul-menu-reduzido"
        className="yuo"
        style={navState}
      >
       

        <div id="traducao"  >
          <LanguageSwitcher />
        </div>

        <div id="social-links-menu-reduzido">
          <a href="https://www.facebook.com/pumpkintechnology/">
            <img src={facebook} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/pumpkin.tech/">
            <img src={instagram} alt="instagram" />
          </a>
          <a href="https://www.linkedin.com/company/pumpkin-tech/mycompany/?viewAsMember=true">
            <img src={linkedin} alt="linkedin" />
          </a>
          <a href="https://api.whatsapp.com/send?phone=351963732348">
            <img src={whatsapp} alt="whatsapp" />
          </a>
          <a href="mailto:sac@pumpkintech.com.br?">
            <img src={email} alt="email" />
          </a>
        </div>
      </section>
    </div>
  );
}
