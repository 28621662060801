import { EmailUnsubscribe } from "../components/email/EmailUnsubscribe"
import { FooterUnsubscribe } from "../components/Global/Footer/FooterUnsubscribe"
import { NavBarUnsubscribe } from "../components/Global/NavBar/NavBarUnsubscribe"


export function Unsubscribe(){
  return(
    <>
    <NavBarUnsubscribe/>
    <EmailUnsubscribe/>
    <FooterUnsubscribe/>
    </>
  )
}