/* Importações do SVG */
import tecAws from "../../assets/Technologies/tec1.svg";
import tecGoogleCloud from "../../assets/Technologies/tec10.svg";
import tecFlutter from "../../assets/Technologies/tec11.svg";
import tecAndroid from "../../assets/Technologies/tec12.svg";
import tecNode from "../../assets/Technologies/tec13.svg";
import tecAngular from "../../assets/Technologies/tec14.svg";
import tecJava from "../../assets/Technologies/tec15.svg";
import tecMicrosoftAzure from "../../assets/Technologies/tec16.svg";
import tecCloud from "../../assets/Technologies/tec2.svg";
import tecFigma from "../../assets/Technologies/tec3.svg";
import tecPython from "../../assets/Technologies/tec4.svg";
import tecPhp from "../../assets/Technologies/tec5.svg";
import tecC from "../../assets/Technologies/tec6.svg";
import tecReact from "../../assets/Technologies/tec7.svg";
import tecIos from "../../assets/Technologies/tec8.svg";
import tecNetCore from "../../assets/Technologies/tec9.svg";

/* ######################################## */

import seta from "../../assets/Technologies/seta-tec.svg";
import "../../styles/index/Technologies.css";
import { i18n } from "../../translate/i18n";

export function Technologies() {
  return (
    <>
      <div className="tecnologias">
        <div id="bloco-tec"></div>
        <img id="seta-tec" src={seta} alt="" />

        <p id="Tec-p">{i18n.t("titles.tecnologias")}</p>
        <div className="section-tec">
          <div className="container-tec-A">
            <div className="tecA1">
              <img
                id="tec1"
                className="tecnologias-svg"
                src={tecAws}
                alt="aws"
              />
              <img
                id="tec2"
                className="tecnologias-svg"
                src={tecCloud}
                alt="cloud"
              />
              <img
                id="tec3"
                className="tecnologias-svg"
                src={tecFigma}
                alt="figma"
              />
              <img
                id="tec4"
                className="tecnologias-svg"
                src={tecPython}
                alt="python"
              />
            </div>
            <div className="tecA2">
              <img
                id="tec5"
                className="tecnologias-svg"
                src={tecPhp}
                alt="php"
              />
              <img id="tec6" className="tecnologias-svg" src={tecC} alt="C++" />
              <img
                id="tec-svg-1 tec7"
                className="tecnologias-svg"
                src={tecReact}
                alt="react"
              />
              <img
                id="tec-svg-1 tec8"
                className="tecnologias-svg"
                src={tecIos}
                alt="Ios"
              />
            </div>
          </div>
          <div className="container-tec-B">
            <div className="tecB1">
              <img className="tecnologias-svg" src={tecNetCore} alt="NetCore" />
              <img
                className="tecnologias-svg"
                src={tecGoogleCloud}
                alt="GoogleCLoud"
              />
              <img className="tecnologias-svg" src={tecFlutter} alt="Flutter" />
              <img className="tecnologias-svg" src={tecAndroid} alt="Android" />
            </div>
            <div className="tecB2">
              <img className="tecnologias-svg" src={tecNode} alt="Node" />
              <img className="tecnologias-svg" src={tecAngular} alt="Angular" />
              <img id="tec-svg-2" src={tecJava} alt="Java" />
              <img
                id="tec-svg-2"
                src={tecMicrosoftAzure}
                alt="Microsoft Azure"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
