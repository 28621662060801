import ReactGA from 'react-ga';
import { useEffect } from 'react';
import { Footer } from '../components/Global/Footer/Footer';
import { NavBar } from '../components/Global/NavBar/NavBar';
import { Pumpkin } from '../components/Index/Pumpkin';
import { Services } from '../components/Index/Services';
import { Methodology } from '../components/Index/Methodology';
import { Technologies } from '../components/Index/Technologies';
import { Contact } from '../components/Index/Contact';
import { Home } from '../components/Index/Home';

const trackingCode = 'UA-254713901-1';
ReactGA.initialize(trackingCode);

export function Index() {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <>
            <NavBar page='home'/>
            <Home />
            <Pumpkin />
            <Services/>
            <Methodology />
            <Technologies/>
            <Contact />
            <Footer/> 
        </>
    )
}