const messages = {
  en: {
    translations: {
      titles: {
        blocoPumpkin:
          "We are a technology company focused in connecting people and transforming businesses.",
        todoNegocioDigital: "Every business is ",
        todoNegocio:
          "We develop technology that speeds up change and transforms businesses.",
        produtos: "Products and Digital Experience",
        metodologia: "How we do it?",
        carousel1: "Immersion and research",
        carousel2: "Service Design",
        carousel3: "Metrics Evolution",
        carousel4: "Design Sprint",
        carousel5: "Usability and Visual Design",
        carousel6: "Usability Testing",
        tecnologias: "Technology",
        contato: "Send us a message!",
        servicos: "Services",
        metodologiaMenu: "Methodology",
        pumpkin: "Pumpkin",
        contacts: "Contact",
      },

      texts: {

        contact:{
          obrigado: "Thank you!",
          logo: "We'll be in touch sonn."
        },

        messages: {
          blocoPumpkin1:
            "We were born in the digital culture era and strive to create unique and innovative solutions molded to the specific needs of our Clients. ",
          blocoPumpkin2:
            "We are supported by a qualified Team, who are totally dedicated to our mission, simplifying technology to give your project the best results.",
          todoNegocio:
            "We are always by your side, no matter the challenge, building a path of results based on security, transparency, productivity and credibility.",
          produtos:
            "We develop products that simplify interactions between people, products and services. We create technology that promotes change and transforms businesses. ",
          produtos1:
            "We believe that the world is constantly moving, so we never stop. We combine agility with new technology to deliver the best solutions to our Clients.",
          servicosDesign:
            "We structure your ideas in a quick, collaborative and innovative way to make your project real in a short period of time.",
          servicosUser:
            "We develop projects that provide better interaction and aim for an enjoyable experience for consumers.",
          servicosData:
            "Follow up your business data and information, so you can have a strategic vision to identify possible problems,solutions and opportunities.",
          servicosDev:
            "We think about the infrastructure, development and information security, which helps facilitate LGPD compliance process and other regulations.",
          servicosMobile:
            "We give the best of technology available in your fingertips.",
          servicosWeb:
            "We develop web applications in a quick and uncomplicated way, using a modern and secure programming framework.",
          servicosQa:
            "Quality is our number one priority. We conduct systematic tests to make sure that the final product is free from bugs and errors.",
          servicosMicro:
            "We develop well-organized and well divided projects to facilitate software scalability.",
          servicosApi:
            "Possibility of integrating systems while keeping data secure and facility for exchange of information between different programming languages.",

          metodologia:
            "We plan, develop and deliver incredible digital products. Our priority is to attend the demands of our Clients in a swift and transparent way while ensuring quality results. ",
          metodologia1:
            "We understand the needs of our Clients and we map out the best routes to achieve desired outcomes from start to finish.",
          carousel1:
            "We identify the right person to map routes, execute hypotheses and insights validation to solve the issues.",
          carousel2:
            "The service is completely mapped, prioritising the communication between areas and opportunities for new interactions.",
          carousel3:
            "We analyze the data evolution to determine the best strategic direction.",
          carousel4:
            "Development of a clear vision to possible solutions and future steps.",
          carousel5:
            "Special attention given to product componentization, visual standards and usability.",
          carousel6:
            "We raise all possible gaps and identify the improvements that the product needs.",
          footer: "Illustrations by Chattapat (NounProject)",
          footerDireitos: "© 2022 All rights reserved. Pumpkin Tech",
        },

        inputs: {
          name: "Name*",
          email: "E-mail*",
          company: "Company",
          phone: "Phone number",
          subject: "Subject*",
          message: "Message*",
        },
        btn: {
          envio: "Send",
        },
        error: {
          name: "Minimum of 2 characters",
          email: "Invalid email",
          phone: "Invalid phone number",
          
          message: "Minimum of 2 characters",
          required: "Required field",
          max: "Maximum 100 characters",
          max500:"Maximum 500 characters"
        },
        unsubscribe:{
          title: "We'll miss you!",
          message:"You have canceled your subscription and will no longer be able to receive emails from Pumpkin.",
        },
      },
    },
  },
};

export { messages };
