import { Link } from "react-scroll";
import email from "../../../assets/footer/EmailFooter.svg";
import facebook from "../../../assets/footer/FacebookFooter.svg";
import instagram from "../../../assets/footer/InstagramFooter.svg";
import linkedin from "../../../assets/footer/LinkedinFooter.svg";
import logo from "../../../assets/footer/LogoFooter.svg";
import whatsapp from "../../../assets/footer/WhatsappFooter.svg";
import "../../../styles/footer/footer.css";
import { i18n } from "../../../translate/i18n";
import "../../Index/Methodology";
import "../../Index/Pumpkin";
import "../../Index/Services";
export function Footer() {
  return (
    <>
      <div className="container-footer">
        <div className="footer">
          <div className="footerA">
            <img id="logoFooter" src={logo} alt="Logo Pumpkin footer" />

            <Link
              data-testid="menu1"
              className="menu"
              activeClass="active"
              to="pumpkin"
              spy={true}
              smooth={true}
              offset={-150}
              duration={200}
            >
             {i18n.t("titles.pumpkin")}
            </Link>
            <Link
              className="menu"
              activeClass="active"
              to="servicos"
              spy={true}
              smooth={true}
              offset={-80}
              duration={200}
            >
              {i18n.t("titles.servicos")}
            </Link>
            <Link
              className="menu"
              activeClass="active"
              to="pumpkin"
              spy={true}
              smooth={true}
              offset={-120}
              duration={200}
              
            >
              
              {i18n.t("titles.metodologiaMenu")}
            </Link>
            <Link
              className="menu"
              activeClass="active"
              to="fundoContact"
              spy={true}
              smooth={true}
              offset={-120}
              duration={200}
            >
              {i18n.t("titles.contacts")}
            </Link>
          </div>
          <div className="footerB">
            <div id="icons-footer">
              <a href="https://www.facebook.com/pumpkintechnology/">
                <img id="icones-footer" src={facebook} alt="facebook" />
              </a>
              <a href="https://www.instagram.com/pumpkin.tech/">
                <img id="icones-footer" src={instagram} alt="instagram" />
              </a>
              <a href="https://www.linkedin.com/company/pumpkin-tech/mycompany/?viewAsMember=true">
                <img id="icones-footer" src={linkedin} alt="linkedin" />
              </a>
              <a href="https://api.whatsapp.com/send?phone=351963732348">
                <img id="icones-footer" src={whatsapp} alt="whatsapp" />
              </a>
              <a href="mailto:sac@pumpkintech.com.br?">
                <img id="icones-footer" src={email} alt="email" />
              </a>
            </div>
            <div id="p1-footer">
              <p>{i18n.t("texts.messages.footer")}</p>
            </div>
            <div id="p2-footer">
              <p>
                {i18n.t("texts.messages.footerDireitos")}{" "}
                <span>Pumpkin Tech</span>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
