import { i18n } from "../../translate/i18n";
import "../../styles/email/unsubscribe.css"

export function EmailUnsubscribe() {
  return (
    <>
    <section>
     <div className="unsubFundo">
      <h3>{i18n.t('texts.unsubscribe.title')}</h3>
      <p>{i18n.t('texts.unsubscribe.message')}</p>
     </div>
     </section>
    </>
  );
}
