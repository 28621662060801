import { i18n } from "./i18n";

import "../translate/Stylealternator.css"



const languageOption = [
  {
    name: "PT",
    value: "pt-BR",
      
   
  },
  {
    name: "EN",
    value: "en-US",
  }
]
export const LanguageSwitcher = () =>{

  return(
    <div className="translate"  >
      {languageOption.map(languageOption => (
        
        <button onClick={() => { i18n.changeLanguage(languageOption.value);  window.location.reload();} }>
          <span id="novo" 
          style={{
            fontWeight: i18n.language === languageOption.value ? "bold" : "normal",
          }}
          >{languageOption.name}</span>
        </button>
        
        
      )
      )}
       
    </div>

  )
  

}