import Carousel from "react-elastic-carousel";
import img from "../../assets/Methodology/carrousel/Image1.png";
import img2 from "../../assets/Methodology/carrousel/Image2.png";
import img3 from "../../assets/Methodology/carrousel/Image3.png";
import img4 from "../../assets/Methodology/carrousel/Image4.png";
import img5 from "../../assets/Methodology/carrousel/Image5.png";
import img6 from "../../assets/Methodology/carrousel/Image6.png";
import flip from "../../assets/Methodology/flip.svg";
import icon from "../../assets/Methodology/Icon.svg";
import vector from "../../assets/Methodology/Vector.svg";
import "../../styles/index/Methodology.css";
import { i18n } from "../../translate/i18n";

const breakPoints = [
  { width: 375, itemsToShow: 1, itemsToScroll: 1 },
  { width: 768, itemsToShow: 2, itemsToScroll: 2 },
  { width: 834, itemsToShow: 3, itemsToScroll: 3 },
  { width: 1200, itemsToShow: 3, itemsToScroll: 3 },
];
const carouselData = [
  {
    id: 1,
    title: `${i18n.t("titles.carousel1")}`,
    description: `${i18n.t("texts.messages.carousel1")}`,
    image: `${img}`,
    style: "bg-orange",
  },
  {
    id: 2,
    title: `${i18n.t("titles.carousel2")}`,
    description: `${i18n.t("texts.messages.carousel2")}`,
    image: `${img2}`,
    style: "bg-purple",
  },
  {
    id: 3,
    title: `${i18n.t("titles.carousel3")}`,
    description: `${i18n.t("texts.messages.carousel3")}`,
    image: `${img3}`,
    style: "bg-orange",
  },
  {
    id: 4,
    title: `${i18n.t("titles.carousel4")}`,
    description: `${i18n.t("texts.messages.carousel4")}`,
    image: `${img4}`,
    style: "bg-purple",
  },
  {
    id: 5,
    title: `${i18n.t("titles.carousel5")}`,
    description: `${i18n.t("texts.messages.carousel5")}`,
    image: `${img5}`,
    style: "bg-orange",
  },
  {
    id: 6,
    title: `${i18n.t("titles.carousel6")}`,
    description: `${i18n.t("texts.messages.carousel6")}`,
    image: `${img6}`,
    style: "bg-purple",
  },
];

export function Methodology() {
  return (
    <>
      <div id="metodologia" className="bloco2">
        <div id="Sup"></div>
        <div className="fundo">
          <div className="icones">
            <img id="svg" src={icon} alt="" />
            <img id="vector" src={vector} alt="" />
            <h1> {i18n.t("titles.metodologia")}</h1>
          </div>
          <div className="texto">
            <p>
              {i18n.t("texts.messages.metodologia")}
              <br />
              <br />
              {i18n.t("texts.messages.metodologia1")}
            </p>
          </div>
        </div>
      </div>

      {/* @ts-ignore */}

      <Carousel breakPoints={breakPoints} itemsToScroll={3} itemsToShow={3}>
        {carouselData.map((item) => (
          <div className="flip-box">
            <div className="flip-box-inner">
              <div className="flip-box-front">
                <img
                  id="imagem"
                  src={item.image}
                  alt="Imagem de fundo carousel"
                />
                <h3 className="titulo-front">{item.title}</h3>
                <img
                  className="flip-front"
                  src={flip}
                  alt="Icone de rotação do carousel"
                />
              </div>
              <div id="img1" className={`flip-box-back ${item.style}`}>
                <h3 className="titulo">{item.title}</h3>
                <p className="conteudo">{item.description}</p>
                <div className="flips">
                  <img
                    id="flip"
                    src={flip}
                    alt="Icone de rotação do carousel"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}
