import logo from "../../assets/Pumpkin/Logo.svg";
import "../../styles/index/Pumpkin.css";
import { i18n } from "../../translate/i18n";



export function Pumpkin() {
  return (
    <>

    
      <div id="pumpkin" className="bloco1">
        <div className="col-a">
          <img src={logo} alt="Logo da Pumpkin" />
          <p id="text1">
            {i18n.t('titles.blocoPumpkin')}
          </p>
         
          <p id="text2">
            {i18n.t('texts.messages.blocoPumpkin1')}
            <br />
            <br />
            {i18n.t('texts.messages.blocoPumpkin2')}
          </p>
        </div>
        <div className="col-b"></div>
      </div>

      <div className="digital">
    <p id="titulo">{i18n.t('titles.todoNegocioDigital')}<span> digital.</span></p>
        <div className="bloco">
          <div className="col1"></div>
          <div className="col2">
            <p id="p1">
              {i18n.t('titles.todoNegocio')}
            </p>

            <p id="p2">
              {i18n.t('texts.messages.todoNegocio')}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
