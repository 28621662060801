import animationData from "../../assets/Lotties/pumpkin.json";
import animationDataEng from "../../assets/Lotties/pumpkinEng.json";
import animationDataSolo from "../../assets/Lotties/solo.json";
import { Constants } from "../../constants/constantes";
import iconCerto from "../../assets/Contact/Check.svg";
import ReactInputMask from "react-input-mask";
import logo from "../../assets/Contact/IconeContato.svg";
import seta from "../../assets/Contact/seta.svg";
import "../../styles/index/Contact.css";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useState } from "react";
import React from "react";
import { Animated } from "react-animated-css";
import Lottie from "react-lottie";
import { i18n } from "../../translate/i18n";
import axios from "axios";

const defaultOptions = {
  loop: true,
  autoplay: false,
  autoplayTimeout: 1000,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const defaultOptionsEng = {
  loop: true,
  autoplay: false,
  autoplayTimeout: 1000,
  animationData: animationDataEng,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const defaultOptionsSolo = {
  loop: true,
  autoplay: false,
  autoplayTimeout: 1000,
  animationData: animationDataSolo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const schema = yup.object().shape({
  name: yup
    .string()
    .min(2, `${i18n.t("texts.error.name")}`)
    .max(100, `${i18n.t("texts.error.max")}`),
  email: yup
    .string()
    .email(`${i18n.t("texts.error.email")}`)
    .required(`${i18n.t("texts.error.required")}`),
  phone: yup
    .string()
    .test(
      "Validação de celular",
      `${i18n.t("texts.error.phone")}`,
      function (phoneValidation) {
        const valuePhone = phoneValidation
          ?.split("")
          .filter((value) => !isNaN(parseInt(value)))
          .join("");

        return valuePhone?.length === 0 || (valuePhone || "").length === 11;
      }
    ),

  company: yup.string().max(100, `${i18n.t("texts.error.max")}`),
  subject: yup.string().required(`${i18n.t("texts.error.required")}`),
  message: yup
    .string()
    .max(500, `${i18n.t("texts.error.max500")}`)
    .min(2, `${i18n.t("texts.error.name")}`),
});

export function Contact() {
  <div id="teste12"></div>;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [iconEnviar, setIsIconEnviar] = useState<boolean>(false);
  const [visible, setIsVisible] = useState<boolean>(false);
  function handleDados(dados: any) {
    setTimeout(() => {
      setIsVisible(true);
    }, 1000);
    setIsIconEnviar(true);

    setTimeout(() => {
      setIsVisible(false);
    }, 5000);
    setTimeout(() => {
      setIsIconEnviar(false);
    }, 3000);

    const endpoint = "https://api.pumpkintech.com.br/notion/table/institucional";
    const headers = {
      "content-type": "application/json",
      "X-API-KEY": "fsmZqUXX6i3dtRuho3qWz9I2dmDhx44M3m1rf38D"
    };
    const body = {
      subject: `${dados.subject}`,
      email: `${dados.email}`,
      company: `${dados.company}`,
      message: `${dados.message}`,
      name: `${dados.name}`,
      phone: `${dados.phone}`,
      emailLanguage: i18n.language,
    };

    axios
      .post(
        endpoint,
        body,

        {
          headers: headers,
        }
      )
      .then((res) => reset())
      .catch((err) => console.log(err));
  }

  function formContacts() {
    return (
      <div id="fundoContact">
        <div>
          <Animated
            animationIn="bounceInLeft"
            animationOut="bounceOutRight"
            animationInDuration={2000}
            animationOutDuration={2000}
            isVisible={!visible}
          >
            <div className="fundoContato">
              <div className="fale">
                <img src={logo} alt="logo" />
                <h3>{i18n.t("titles.contato")}</h3>
              </div>
              <div className="contact">
                <div className="inputA">
                  <div className="entrada">
                    <input
                      autoComplete="off"
                      className="inputEntrada"
                      {...register("name")}
                      placeholder={i18n.t("texts.inputs.name")}
                      type="text"
                      name="name"
                      id=""
                    />
                    {errors.name?.message ? (
                      <span id="erro">{errors.name?.message}</span>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="entrada">
                    <input
                      autoComplete="off"
                      className="inputEntrada"
                      {...register("email")}
                      placeholder={i18n.t("texts.inputs.email")}
                      type="email"
                      name="email"
                      id=""
                    />
                    <span id="erro">{errors.email?.message}</span>
                  </div>

                  <div className="entrada">
                    <input
                      autoComplete="off"
                      className="inputEntrada"
                      {...register("company")}
                      placeholder={i18n.t("texts.inputs.company")}
                      type="text"
                      name="company"
                      id=""
                    />
                    <span id="erro">{errors.company?.message}</span>
                  </div>
                  <div className="entrada">
                    <ReactInputMask
                      className="inputEntrada"
                      {...register("phone")}
                      mask="(99) 99999-9999"
                      placeholder={i18n.t("texts.inputs.phone")}
                      type="text"
                      name="phone"
                      id=""
                    />
                    <span id="erro">{errors.phone?.message}</span>
                  </div>
                </div>
                <div className="inputB">
                  <div className="entrada">
                    <input
                      autoComplete="off"
                      className="inputEntrada"
                      {...register("subject")}
                      placeholder={i18n.t("texts.inputs.subject")}
                      type="text"
                      name="subject"
                      id=""
                    />
                    <span id="erro">{errors.subject?.message}</span>
                  </div>
                  <div id="mensagem">
                    <textarea
                      {...register("message")}
                      placeholder={i18n.t("texts.inputs.message")}
                      /* maxLength={500} */
                      name="message"
                      id="textarea"
                      rows={2}
                      cols={20}
                    ></textarea>
                    <span id="erro">{errors.message?.message}</span>
                  </div>
                </div>
              </div>

              <button id="botao" type="submit">
                {iconEnviar === false ? (
                  `${i18n.t("texts.btn.envio")}`
                ) : (
                  <img
                    className="w3-animate-opacity"
                    id="iconEnviar"
                    src={iconCerto}
                    alt="Simbolo de enviar"
                  />
                )}
              </button>
              <div id="fundoBotao"></div>

              <img id="seta" src={seta} alt="" />
            </div>
          </Animated>
        </div>
      </div>
    );
  }

  function lottieContact() {
    return (
      <div id="fundoContact">
        <div>
          <div className="lottiePumpkin">
            {i18n.language === Constants.TRANSLATE_IDIO ? (
              <Lottie options={defaultOptions} height={726} width={200} />
            ) : (
              <Lottie options={defaultOptionsEng} height={300} width={200} />
            )}
          </div>

          <div className="lottiePumpkin2">
            <Lottie options={defaultOptionsSolo} height={726} width={375} />
            <div id="agradecimentoContato">
              <h1>{i18n.t("texts.contact.obrigado")}</h1>
              <br />
              <br />
              <h2>{i18n.t("texts.contact.logo")}</h2>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function animations(children: React.ReactNode) {
    return (
      <Animated
        animationIn="bounceInLeft"
        animationOut="bounceOutRight"
        animateOnMount={true}
        animationInDuration={2000}
        isVisible={visible}
      >
        {children}
      </Animated>
    );
  }

  return (
    <form
      id="FormContact "
      className="fundoContact"
      onSubmit={handleSubmit(handleDados)}
    >
      {!visible && formContacts()}
      {visible && animations(lottieContact())}
    </form>
  );
}
