import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Index } from "./pages/index";

import "bootstrap/dist/css/bootstrap.css";
import { Unsubscribe } from "./pages/emailUnsubscribe";
import "./styles/global.css";

function App() {
  return (
    <Router basename="/index.html">
      <Switch>
        <Route path="/" component={Index} exact />
        <Route path="/unsubscribeemail" component={Unsubscribe} exact/>
      </Switch>
    </Router>
  );
}

export default App;
