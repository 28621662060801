import logo from "../../assets/Home/novaVersao/tecnologiaLogo.svg"
import logoEng from "../../assets/Home/novaVersao/tecnologiaLogoEng.svg"

import email from "../../assets/Home/Email.svg";
import facebook from "../../assets/Home/Facebook.svg";
import instagram from "../../assets/Home/Instagram.svg";
import linkedin from "../../assets/Home/linkedin.svg";
import seta from "../../assets/Home/seta.svg";
import whatsapp from "../../assets/Home/whatsapp.svg";
import { Constants } from "../../constants/constantes";
import "../../styles/index/Home.css";
import { i18n } from "../../translate/i18n";
import "../Index/Methodology";
import Link from "react-scroll/modules/components/Link";

export function Home() {

  
  return (
    <>
      <div className="background" >

        <img id="tecnologiaLogo" src={i18n.language === Constants.TRANSLATE_IDIO ? logo : logoEng } alt="" />




        <div 
          className="icons d-flex social-icons-container justify-content-evenly px-5"
          id="social-links"
        >
          <a  href="https://www.facebook.com/pumpkintechnology/" > 
            <img id="icones" src={facebook} alt="facebook" />
          </a>
          <a href="https://www.instagram.com/pumpkin.tech/">
            
            <img data-testid="icones" src={instagram} alt="instagram" />
          </a>
          <a href="https://www.linkedin.com/company/pumpkin-tech/mycompany/?viewAsMember=true">
            <img id="icones" src={linkedin} alt="linkedin" />
          </a>
          <a href="https://api.whatsapp.com/send?phone=351963732348">
            <img id="icones" src={whatsapp} alt="whatsapp" />
          </a>
          <a href="mailto:sac@pumpkintech.com.br?">
            <img id="icones" src={email} alt="email" />
          </a>
        </div>
        <div id="header-arrow-down">
          <Link 
                activeClass="active"
                to="pumpkin"
                spy={true}
                smooth={true}
                offset={-110}
                duration={200}>
            <img src={seta} alt="seta de ancoragem" />
          </Link>
        </div>
      </div>
    </>
  );
}
